/* purgecss start ignore */

@import "tailwindcss/base";
@import "tailwindcss/components";

/* purgecss end ignore */

@import "tailwindcss/utilities";

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-image: radial-gradient(ellipse at bottom, #5091DD 0%, #3e3e41 100%);

}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.dark-theme body {
    background-color: theme("colors.dark");
    color: theme("colors.darkFont");
}

.btn {
    @apply mx-2 rounded-lg py-2 px-4;
}

a {
    cursor: pointer;
}

.todo-btn {
    width: 200px;
}

.todo-btn--selected {
    @apply bg-blue-700;
}

.carousel-container .slick-slide {
    display: flex;
    justify-content: center;
}

.carousel-item img {
    width: 100%;
    max-width: 500px;
    height: auto;
    object-fit: cover;
}

.slick-dots {
    bottom: 10px;
}

.slick-dots li button:before {
    color: white;
}

.slick-prev, .slick-next {
    color: white;
}
