.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-float infinite 3s ease-in-out;
    }
}

.App-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}

.App-link {
    color: rgb(112, 76, 182);
}

.App-content {
    font-size: calc(10px + 2vmin);
}

.App-content > div,
.App-content > h2 {
    width: 900px;
    margin-left: auto;
    margin-right: auto;
}

.scrollChev {
    position: fixed;
    right: 5%;
    bottom: 5%;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

@keyframes App-logo-float {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(0);
    }
}

@media (max-width: 640px) {
    .social-link {
        width: 100%;
        text-align: center;
    }
    .scrollChev {
        right: 2%;
        bottom: 2%;
    }
}
